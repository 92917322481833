import { appStore, googlePlay } from '@/const';
import { navigate } from '@reach/router';
import { useEffect } from 'react';

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
function getMobileOperatingSystem() {
  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
}

const DownloadRedirect = () => {
  useEffect(() => {
    const os = getMobileOperatingSystem();
    switch (os) {
      case 'Android':
        window.open(googlePlay, '_top').focus();
        break;
      case 'iOS':
        window.open(appStore, '_top').focus();
        break;
      default:
        navigate('/');
        break;
    }
  }, []);
  return null;
};

export default DownloadRedirect;
